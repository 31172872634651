import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const defaultLayoutMeta = {
  layout: 'default',
};

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      ...defaultLayoutMeta,
      metaTags: [
        {
          name: 'description',
          content: 'CLASS – система поддержки принятия решений на основе полного и объективного анализа временных рядов пространственных данных',
        },
      ],
      title: 'CLASS – система поддержки принятия решений на основе полного и объективного анализа временных рядов пространственных данных',
    },
    component: HomeView,
    children: [
      {
        path: '#statistic',
        name: 'statistic',
        component: HomeView,
      },
    ],
  },
  {
    path: '/about',
    name: 'about-link',
    redirect: { name: 'about' },
    meta: {
      ...defaultLayoutMeta,
      title: 'О нас',
      metaTags: [
        {
          name: 'description',
          content: 'О нас',
        },
      ],
    },
    component: () => import('../views/aboutUs/AboutLink.vue'),
    children: [
      {
        path: '',
        name: 'about',
        meta: {
          ...defaultLayoutMeta,
          title: '',
        },
        component: () => import('../views/aboutUs/AboutUs.vue'),
      },
      {
        path: 'team',
        name: 'team',
        meta: {
          ...defaultLayoutMeta,
          title: 'Команда',
          metaTags: [
            {
              name: 'description',
              content: 'Команда',
            },
          ],
        },
        component: () => import('../views/aboutUs/ProjectTeam.vue'),
      },
    ],
  },
  {
    path: '/news',
    name: 'news-link',
    redirect: { name: 'news' },
    meta: { ...defaultLayoutMeta, title: '' },
    component: () => import('../views/news/NewsLink.vue'),
    children: [
      {
        path: '',
        name: 'news',
        meta: {
          ...defaultLayoutMeta,
          title: 'Новости',
          metaTags: [
            {
              name: 'description',
              content: 'Новости',
            },
          ],
        },
        component: () => import('../views/news/NewsView.vue'),
      },
      {
        path: '/news/:id_url',
        name: 'news-details',
        meta: {
          ...defaultLayoutMeta,
          title: 'Новости',
          metaTags: [
            {
              name: 'description',
              content: 'Подробности новости',
            },
          ],
        },
        component: () => import('../views/news/NewsDetails.vue'),
        props: (route) => {
          const parts = route.params.id_url.split('_');
          return { id: parseInt(parts[0], 10), url: parts[1] };
        },
      },
    ],
  },

  {
    path: '/articles',
    name: 'articles',
    meta: {
      ...defaultLayoutMeta,
      title: 'Статьи',
      metaTags: [
        {
          name: 'description',
          content: 'Статьи',
        },
      ],
    },
    component: () => import('@/views/ArticlesView.vue'),
  },
  {
    path: '/products/class',
    name: 'class-agro-link',
    redirect: { name: 'class' },
    meta: {
      ...defaultLayoutMeta,
      title: 'CLASS',
      metaTags: [
        {
          name: 'description',
          content: 'Облачная программная платформа CLASS предназначена для создания продуктов и сервисов, основанных на методах глобального территориального мониторинга районов интереса, анализа и оценки состояния исследуемых объектов по получаемым временным рядам разнородных геопространственных данных, а также прогнозирования развития состояний объекта мониторинга и формирования рекомендаций для оптимизации хода изменения данных состояний.',
        },
      ],
    },
    component: () => import('../views/products/ClassAgro.vue'),
    children: [
      {
        path: '',
        name: 'class',
        meta: {
          ...defaultLayoutMeta,
          title: '',
        },
        component: () => import('../views/products/ClassAgroHome.vue'),
      },
      {
        path: 'data-mining',
        name: 'data-mining',
        meta: {
          ...defaultLayoutMeta,
          title: 'Платформа интеллектуального анализа данных',
          metaTags: [
            {
              name: 'description',
              content: 'Платформа интеллектуального анализа данных (Data Analytics Platform, DAP) – это облачная платформа, предназначенная для интеллектуального геопространственного анализа с возможностями создания и использования ML- моделей регрессии, кластеризации и классификации с географической привязкой, а также специальных моделей, основанных на физических законах.',
            },
          ],
        },
        component: () => import('../views/products/ClassAgroMining.vue'),
      },
      {
        path: 'data-hybrid',
        name: 'data-hybrid',
        meta: {
          ...defaultLayoutMeta,
          title: 'Платформа сбора и обработки разнородных данных',
          metaTags: [
            {
              name: 'description',
              content: 'Платформа сбора и обработки разнородных данных предназначена для автоматизации процесса сбора, тематической обработки и хранения разнородных неструктурированных данных, поступающих в платформу из разных источников и форматов с целью оптимизации затрат пользователей и повышения эффективности при решении задач оценки урожайности.',
            },
          ],
        },
        component: () => import('../views/products/ClassAgroHybrid.vue'),
      },
      {
        path: 'data-sensing',
        name: 'data-sensing',
        meta: {
          ...defaultLayoutMeta,
          title: 'Платформа сбора и обработки данных ДЗЗ',
          metaTags: [
            {
              name: 'description',
              content: 'Платформа сбора и обработки данных дистанционного зондирования Земли (ДЗЗ) осуществляет потоковую загрузку данных оптической и радарной космической съемки, выполняет автоматический расчет спектральных индексов для мониторинга вегетации растений. В платформу загружается и выполняется анализ информации о погоде.',
            },
          ],
        },
        component: () => import('../views/products/ClassAgroSensing.vue'),
      },
    ],
  },
  {
    path: '/products/class-forest',
    name: 'class-forest',
    meta: {
      ...defaultLayoutMeta,
      title: 'CLASS.FOREST',
      metaTags: [
        {
          name: 'description',
          content: 'Леса являются господствующей формой растительности на земле. Они считаются залогом существования биосферы и благополучия  сопутствующих сред.',
        },
      ],
    },
    component: () => import('../views/products/ClassForest.vue'),
  },
  {
    path: '/products/class-carbon',
    name: 'class-carbon',
    meta: {
      ...defaultLayoutMeta,
      title: 'СLASS.CARBON',
      metaTags: [
        {
          name: 'description',
          content: 'Объем мирового рынка торговли карбоновыми квотами оценивается в $100 млрд. К 2030-му году ожидается его многократный рост, что сделает карбоновую отрасль одной из ведущих в мировой экономике.',
        },
      ],
    },
    component: () => import('../views/products/ClassCarbon.vue'),
  },
  {
    path: '/products/class.cloud/risc-agro',
    name: 'risc-agro',
    meta: {
      ...defaultLayoutMeta,
      title: 'RiskAgro',
      metaTags: [
        {
          name: 'description',
          content: 'RiskAgro – информационно-аналитический онлайн-сервис в области агрострахования для оценки страхователя, заключения и сопровождения договоров страхования, а также урегулирования страховых случаев',
        },
      ],
    },
    component: () => import('../views/products/RiscAgro.vue'),
  },
  {
    path: '/products/class.cloud/agro-metrika',
    name: 'agro-metrika',
    meta: {
      ...defaultLayoutMeta,
      title: 'AGROMETRIKA',
      metaTags: [
        {
          name: 'description',
          content: 'Сервис «AGROMETRIKA» реализован на базе системы class, разработанной компанией ctrl2go, предназначен для автоматизации управления системой земледелия сельскохозяйственного предприятия и внутрихозяйственного учета земель сельскохозяйственного назначения.  ',
        },
      ],
    },
    component: () => import('../views/products/AgroMetrika.vue'),
  },
  {
    path: '/products/class.cloud/GeoCLASS.Cadastre',
    name: 'geo-cadastre',
    meta: {
      ...defaultLayoutMeta,
      title: 'GeoCLASS.Cadastre',
      metaTags: [
        {
          name: 'description',
          content: 'GeoCLASS.Cadastre',
        },
      ],
    },
    component: () => import('../views/products/ClassCadastre.vue'),
  },
  // {
  //   path: '/products/scoring',
  //   name: 'scoring',
  //   meta: { ...defaultLayoutMeta, title: 'Scoring',
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Scoring',
  //       },
  // ],
  //    },
  //   component: () => import('../views/products/ClassScoring.vue'),
  // },
  {
    path: '/products/class-mobile',
    name: 'class-mobile',
    meta: {
      ...defaultLayoutMeta,
      title: 'CLASS.MOBILE',
      metaTags: [
        {
          name: 'description',
          content: 'В линейке продуктов, входящих в систему class, разработаны и применяются два мобильных приложения: FIELD EXPERIMENT и DocPlant',
        },
      ],
    },
    component: () => import('../views/products/ClassMobile.vue'),
    children: [
      {
        path: 'field-experiment',
        name: 'field-experiment',
        meta: {
          ...defaultLayoutMeta,
          title: 'Мобильное приложение FIELD EXPERIMENT',
          metaTags: [
            {
              name: 'description',
              content: 'Мобильное приложение FIELD EXPERIMENT',
            },
          ],
        },
        component: () => import('../views/products/ClassMobileField.vue'),
      },
      {
        path: 'doc-plant',
        name: 'doc-plant',
        meta: {
          ...defaultLayoutMeta,
          title: 'Мобильное приложение DocPlant',
          metaTags: [
            {
              name: 'description',
              content: 'Мобильное приложение DocPlant',
            },
          ],
        },
        component: () => import('../views/products/ClassMobilePlant.vue'),
      },
    ],
  },
  {
    path: '/products/services/crop-area',
    name: 'crop-area',
    meta: {
      ...defaultLayoutMeta,
      title: 'Классификация посевных площадей',
      metaTags: [
        {
          name: 'description',
          content: 'Наша команда разрабатывает технологию раннего распознавания видов сельскохозяйственных культур по набору мультиспектральных космических снимков на территориях масштаба региона.',
        },
      ],
    },
    component: () => import('../views/products/ServiceCropArea.vue'),
  },
  {
    path: '/products/services/yield-eval',
    name: 'yield-eval',
    meta: {
      ...defaultLayoutMeta,
      title: 'Оценка урожайности',
      metaTags: [
        {
          name: 'description',
          content: 'ML – модели, которые мы используем для оценки урожайности – это нейросетевые модели, «бустинговые» модели (Random Forest, CatBoost и аналогичные) и другие категории ML – моделей, в том числе простые – такие, как К ближайших соседей, которые позволяют предсказать урожайность на основе ряда входных переменных.',
        },
      ],
    },
    component: () => import('../views/products/ServiceYieldEval.vue'),
  },
  {
    path: '/products/services/forecast',
    name: 'forecast',
    meta: {
      ...defaultLayoutMeta,
      title: 'Прогноз урожайности',
      metaTags: [
        {
          name: 'description',
          content: 'Прогноз урожайности осуществляется при помощи имитационных моделей продуктивности на основе погодных сценариев.',
        },
      ],
    },
    component: () => import('../views/products/ServiceForecast.vue'),
  },
  {
    path: '/products/services/loss-eval',
    name: 'loss-eval',
    meta: {
      ...defaultLayoutMeta,
      title: 'Оценка ущерба',
      metaTags: [
        {
          name: 'description',
          content: 'В оценке ущерба при гибели и повреждении урожая заинтересованы как страховые компании, для того чтобы оценить свои страховые риски, так и сельхозпроизводители, арендаторы и фермеры, которые страхуют себя от неурожая из-за стихийных бедствий или других неблагоприятных условий.',
        },
      ],
    },
    component: () => import('../views/products/ServiceLossEval.vue'),
  },
  {
    path: '/products/services/rank-agro',
    name: 'rank-agro',
    meta: {
      ...defaultLayoutMeta,
      title: 'Оценка и ранжирование сельскохозяйственных земель',
      metaTags: [
        {
          name: 'description',
          content: 'Оценка и ранжирование сельскохозяйственных земель позволяет определить оптимальные пути их использования, повысить эффективность и избежать нерациональных трат.  ',
        },
      ],
    },
    component: () => import('../views/products/ServiceRankAgro.vue'),
  },
  {
    path: '/products/services/invasive-plants',
    name: 'invasive-plants',
    meta: {
      ...defaultLayoutMeta,
      title: 'Борьба с инвазивными видами растений',
      metaTags: [
        {
          name: 'description',
          content: 'Инвазивными видами растений называют чужеродные виды, которые попали на новую территорию и начали активно размножаться, вытесняя местные виды. Многие из таких растений наносят вред здоровью людей, окружающей природе и приводят к изменению и сокращению видового разнообразия растительного мира.',
        },
      ],
    },
    component: () => import('../views/products/ServiceInvasive.vue'),
  },
  {
    path: '/products/services/obs-media',
    name: 'obs-media',
    meta: {
      ...defaultLayoutMeta,
      title: 'Мониторинг тематических СМИ на территориях проведения исследований',
      metaTags: [
        {
          name: 'description',
          content: 'Ежедневно система CLASS проводит мониторинг индийских СМИ с целью выявления событий, влияющих на агропроизводство в области растениеводства.',
        },
      ],
    },
    component: () => import('../views/products/ServiceMedia.vue'),
  },
  {
    path: '/products/services/obs-weather',
    name: 'obs-weather',
    meta: {
      ...defaultLayoutMeta,
      title: 'Мониторинг и прогнозирование метеоусловий',
      metaTags: [
        {
          name: 'description',
          content: 'Подсистема сбора, обработки и хранения метеоданных импортирует, унифицирует и хранит погодные данные, получаемые от внешних поставщиков метеоданных, она предназначена для сбора исторических погодных данных, оценки текущего состояния атмосферы, составления прогнозов развития атмосферных явлений и подготовки данных для других модулей системы CLASS, в том числе для моделирования развития сельскохозяйственных культур.',
        },
      ],
    },
    component: () => import('../views/products/ServiceWeather.vue'),
  },
  {
    path: '/industries/agriculture',
    name: 'agriculture-link',
    meta: {
      ...defaultLayoutMeta,
      title: 'Сельское хозяйство',
      metaTags: [
        {
          name: 'description',
          content: 'Сельское хозяйство',
        },
      ],
    },
    redirect: { name: 'agriculture' },
    component: () => import('../views/industries/AgricultureLink.vue'),
    children: [
      {
        path: '',
        name: 'agriculture',
        meta: {
          ...defaultLayoutMeta,
          title: '',
        },
        component: () => import('../views/industries/AgricultureView.vue'),
      },
      {
        path: 'plant-growing',
        name: 'plant-growing',
        meta: {
          ...defaultLayoutMeta,
          title: 'Растениеводство',
          metaTags: [
            {
              name: 'description',
              content: 'Растениеводство – это наука о культурных растениях и методах их выращивания с целью получения высоких урожаев наилучшего качества с наименьшими затратами труда и средств (частное земледелие).',
            },
          ],
        },
        component: () => import('../views/industries/AgriculturePlant.vue'),
      },
      {
        path: 'stock-raising',
        name: 'stock-raising',
        meta: {
          ...defaultLayoutMeta,
          title: 'Пастбищное животноводство',
          metaTags: [
            {
              name: 'description',
              content: 'Развитие системы CLASS направлено на решение описанных проблем пастбищного животноводства за счёт использования материалов ДЗЗ (спутниковых и БПЛА) для сбора необходимой информации и технологий искусственного интеллекта для ее обработки и анализа.',
            },
          ],
        },
        component: () => import('../views/industries/AgricultureRaising.vue'),
      },
    ],
  },
  {
    path: '/industries/ins-finance',
    name: 'ins-finance',
    meta: {
      ...defaultLayoutMeta,
      title: 'Страхование и финансы',
      metaTags: [
        {
          name: 'description',
          content: 'Для оценки эффективности инвестиций и страховых рисков страховым и инвестиционным компаниям необходимо иметь актуальную и точную информацию, предоставляемую независимыми источниками.',
        },
      ],
    },
    component: () => import('../views/industries/InsFinance.vue'),
  },
  {
    path: '/industries/forestry',
    name: 'forestry',
    meta: {
      ...defaultLayoutMeta,
      title: 'Лесное хозяйство',
      metaTags: [
        {
          name: 'description',
          content: 'Рациональное использование лесных ресурсов, управление лесным хозяйством, требуют наличия полной и достоверной информации обо всех природных и техногенных процессах на территории региона. Отечественный и зарубежный опыт показывает, что такую полноту информации могут обеспечить аэрокосмическая съемка и геоинформационные технологии в сочетании с традиционно используемыми источниками информации.',
        },
      ],
    },
    component: () => import('../views/industries/ForestryView.vue'),
  },
  {
    path: '/industries/ecology',
    name: 'ecology-link',
    redirect: { name: 'ecology' },
    meta: {
      ...defaultLayoutMeta,
      title: 'Экология',
      metaTags: [
        {
          name: 'description',
          content: 'Экология',
        },
      ],
    },
    component: () => import('../views/industries/EcologyLinks.vue'),
    children: [
      {
        path: '',
        name: 'ecology',
        meta: {
          ...defaultLayoutMeta,
          title: '',
        },
        component: () => import('../views/industries/EcologyView.vue'),
      },
      {
        path: 'carbon-projects',
        name: 'carbon-projects',
        meta: {
          ...defaultLayoutMeta,
          title: 'Карбоновые проекты',
          metaTags: [
            {
              name: 'description',
              content: 'Ctrl2GO разрабатывает технологии высокоточного мониторинга углеродного баланса природных экосистем, апробирует технологии регенеративного земледелия, создает и управляет карбоновыми полигонами.',
            },
          ],
        },
        component: () => import('../views/industries/EcologyCarbon.vue'),
      },
      {
        path: 'invasive-plant',
        name: 'invasive-plant',
        meta: {
          ...defaultLayoutMeta,
          title: 'Борьба с инвазивными растениями',
          metaTags: [
            {
              name: 'description',
              content: 'Компания CTRL2GO предлагает свои решения для борьбы с инвазивными растениями, в частости борщевиком сосновского. С помощью космических съемок и съемок с беспилотных летательных аппаратов определяется плотность сорняка с точностью до 95%.',
            },
          ],
        },
        component: () => import('../views/products/ServiceInvasive.vue'),
      },
    ],
  },
  {
    path: '/technology',
    name: 'technology-link',
    meta: {
      ...defaultLayoutMeta,
      title: 'Технологии',
      metaTags: [
        {
          name: 'description',
          content: 'Технологии',
        },
      ],
    },
    redirect: { name: 'technology' },
    component: () => import('../views/technology/TechnologyLink.vue'),
    children: [
      {
        path: '',
        name: 'technology',
        meta: {
          ...defaultLayoutMeta,
          title: '',
        },
        component: () => import('../views/technology/TechnologyView.vue'),
      },
      {
        path: 'agro-scouting',
        name: 'agro-scouting',
        meta: {
          ...defaultLayoutMeta,
          title: 'Технология агроскаутинга',
          metaTags: [
            {
              name: 'description',
              content: 'Технология агроскаутинга – это система независимого контроля состояния развития культур в сельхозпроизводстве. В данный раздел можно отнести такие методы, как - полевой опыт (эксперимент) и отбор проб.',
            },
          ],
        },
        component: () => import('../views/technology/AgroScouting.vue'),
      },
      {
        path: 'process-sensing',
        name: 'process-sensing',
        meta: {
          ...defaultLayoutMeta,
          title: 'Обработка материалов ДЗЗ',
          metaTags: [
            {
              name: 'description',
              content: 'Обработку данных ДЗЗ проводят в два этапа: предварительный, направленный на коррекцию и улучшение изображений и тематический, связанный с дешифровкой и распознаванием объектов и явлений.',
            },
          ],
        },
        component: () => import('../views/technology/ProcessSensing.vue'),
      },
      {
        path: 'big-data',
        name: 'big-data',
        meta: {
          ...defaultLayoutMeta,
          title: 'Большие данные (BIG DATA)',
          metaTags: [
            {
              name: 'description',
              content: 'Технология Больших данных (Big Data) — это совокупность инструментов, подходов и методов обработки больших объемов структурированных и неструктурированных данных для решения конкретных задач.',
            },
          ],
        },
        component: () => import('../views/technology/BigData.vue'),
      },
      {
        path: 'satellite-nav',
        name: 'satellite-nav',
        meta: {
          ...defaultLayoutMeta,
          title: 'Технология глобальной спутниковой навигации',
          metaTags: [
            {
              name: 'description',
              content: 'Система, предназначенная для определения местоположения наземных, водных и воздушных объектов, а также низкоорбитальных космических аппаратов.',
            },
          ],
        },
        component: () => import('../views/technology/SatelliteNav.vue'),
      },
      {
        path: 'sensing-space',
        name: 'sensing-space',
        meta: {
          ...defaultLayoutMeta,
          title: 'ДЗЗ из космоса',
          metaTags: [
            {
              name: 'description',
              content: 'Технологии дистанционного зондирования Земли (ДЗЗ) из космоса — незаменимый инструмент изучения и постоянного мониторинга нашей планеты, помогающий эффективно использовать и управлять ее ресурсами. Современные технологии ДЗЗ находят применение практически во всех сферах нашей жизни.',
            },
          ],
        },
        component: () => import('../views/technology/SensingSpace.vue'),
      },
      {
        path: 'sensing-uav',
        name: 'sensing-uav',
        meta: {
          ...defaultLayoutMeta,
          title: 'ДЗЗ с помощью БПЛА',
          metaTags: [
            {
              name: 'description',
              content: 'Дистанционное зондирование, основанное на беспилотных летательных аппаратах (БПЛА), систематически применяется для мониторинга параметров растительности и окружающей среды направленных на оптимизацию деятельности в области экологии, сельском и лесном хозяйстве',
            },
          ],
        },
        component: () => import('../views/technology/SensingUav.vue'),
      },
      {
        path: 'simulation',
        name: 'simulation',
        meta: {
          ...defaultLayoutMeta,
          title: 'Динамическое имитационное моделирование',
          metaTags: [
            {
              name: 'description',
              content: 'Имитационное моделирование (англ. simulation modeling) — метод исследования, при котором изучаемая система заменяется моделью, с достаточной точностью описывающей реальную систему, с которой проводятся эксперименты с целью получения информации об этой системе.',
            },
          ],
        },
        component: () => import('../views/technology/SimulationView.vue'),
      },
      {
        path: 'tech-ai',
        name: 'tech-ai',
        meta: {
          ...defaultLayoutMeta,
          title: 'Технологии AI',
          metaTags: [
            {
              name: 'description',
              content: 'Искусственный интеллект (ИИ, англ. Artificial intelligence, AI) – это технология, а точнее направление современной науки, которое изучает способы обучить компьютер, роботизированную технику, аналитическую систему разумно мыслить также как человек.',
            },
          ],
        },
        component: () => import('../views/technology/TechAi.vue'),
      },
    ],
  },
  {
    path: '/media/gallery',
    name: 'gallery',
    meta: {
      ...defaultLayoutMeta,
      title: 'Галерея',
      metaTags: [
        {
          name: 'description',
          content: 'Галерея',
        },
      ],
    },
    component: () => import('../views/media/GalleryView.vue'),
  },
  {
    path: '/media/video',
    name: 'video',
    meta: {
      ...defaultLayoutMeta,
      title: 'Видео',
      metaTags: [
        {
          name: 'description',
          content: 'Видео',
        },
      ],
    },
    component: () => import('../views/media/VideoView.vue'),
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: {
      ...defaultLayoutMeta,
      title: 'Контакты',
      metaTags: [
        {
          name: 'description',
          content: 'Контакты',
        },
      ],
    },
    component: () => import('../views/ContactsView.vue'),
  },

  {
    path: '/rates',
    name: 'rates',
    meta: {
      ...defaultLayoutMeta,
      title: 'Тарифы',
      metaTags: [
        {
          name: 'description',
          content: 'Тарифы',
        },
      ],
    },
    component: () => import('../views/RatesView.vue'),
  },

  {
    path: '/support',
    name: 'support',
    meta: {
      ...defaultLayoutMeta,
      title: 'Поддержка',
      metaTags: [
        {
          name: 'description',
          content: 'Техническая поддержка',
        },
      ],
    },
    component: () => import('../views/SupportView.vue'),
  },
  {
    path: '/class.pro',
    name: 'class-pro',
    meta: {
      layout: 'landing',
      title: 'CLASS.PRO',
      metaTags: [
        {
          name: 'description',
          content: 'CLASS.PRO',
        },
      ],
    },
    component: () => import('../views/ClassPro/ClassPro.vue'),
  },

  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    meta: {
      ...defaultLayoutMeta,
      title: 'Not Found',
      metaTags: [
        {
          name: 'description',
          content: 'Not Found',
        },
      ],
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return (savedPosition);
  }
  if (to.hash) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          el: to.hash,
          behavior: 'smooth',
          top: 110,
        });
      }, 900);
    });
  }
  return { left: 0, top: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta?.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  if (!nearestWithTitle) return next();
  if (nearestWithTitle?.meta?.metaTags?.length) {
    nearestWithTitle.meta.metaTags.map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        if (key === 'content' && tagDef[key] === 'Подробности новости') {
          tag.setAttribute(key, to.params.title);
        } else {
          tag.setAttribute(key, tagDef[key]);
        }
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
  }

  // Удаление предыдущего канонического тега, если он существует
  const previousCanonical = document.querySelector('link[rel="canonical"]');
  if (previousCanonical) {
    document.head.removeChild(previousCanonical);
  }

  // Добавление нового канонического тега
  const canonicalLink = document.createElement('link');
  canonicalLink.setAttribute('rel', 'canonical');

  canonicalLink.setAttribute('href', `https://class-cloud.ru${to.path}`);
  document.head.appendChild(canonicalLink);
  return next();
});

export default router;
