<template>
<nav class="navigation header_navigation">
  <div class="header_navigation-background">
    <img class="header_navigation-background__img"
     src="/images/background/mobile.png" alt="navigation-background" />
  </div>

  <ul class="navigation__list">
    <li class="navigation__item"
     :class=" { 'disabled' : link.pointer }"
     v-for="link, index in headerLinks" :key="index"
     @keydown="bar">
      <component :is="link.isLink === true ? 'router-link' : 'div'"
      class="navigation__link"
       :class=" { 'disabled' : link.pointer }"
        :to="{ name: link.path }"
        @click.prevent="toggleHamburger">{{link.text}}
        <header-menu-arrow v-if="link.dropdown"/>
      </component>

      <ul class="navigation-dropdown__list" v-if="link.dropdown">
        <li class="navigation-dropdown__item"
         :class=" [ {'disabled' : dropLink.pointer}, dropLink.className]"
          v-for="dropLink, index in link.dropLinks" :key="index">

          <component :is="dropLink.isLink === true ? 'router-link' : 'div'"
           class="navigation-dropdown__link"
           :class=" { 'disabled' : dropLink.pointer }"
            :to="{ name: dropLink.path }"
            @click.prevent="toggleHamburger"
           >{{dropLink.text}}
            <header-menu-arrow v-if="dropLink.dropdown" />
          </component>

          <ul class="navigation-dropdown__list" v-if="dropLink.dropdown">
            <li class="navigation-dropdown__item"
             :class="[ { 'disabled' : dropSecondLink.pointer }, dropSecondLink.className]"
              v-for="dropSecondLink, index in dropLink.dropSecondLinks" :key="index">

              <router-link class="navigation-dropdown__link"
               :class=" { 'disabled' : dropSecondLink.pointer }"
                :to="{ name: dropSecondLink.path }"
                @click.prevent="toggleHamburger">{{dropSecondLink.text}}
              </router-link>

            </li>
          </ul>

        </li>
      </ul>

    </li>
  </ul>
</nav>
</template>

<script>
import HeaderMenuArrow from '@/components/header/HeaderMenuArrow.vue';

export default {
  data() {
    return {
      headerLinks: [{
        text: 'Главная',
        path: 'home',
        dropdown: false,
        isLink: true,
      },
      {
        text: 'О нас',
        path: 'about',
        isLink: true,
      },
      {
        text: 'Медиа',
        path: '',
        dropdown: true,
        isLink: false,
        pointer: true,
        dropLinks: [
          {
            text: 'Новости',
            path: 'news',
            isLink: true,
          },
          {
            text: 'Видео',
            path: 'video',
            isLink: true,
          },
          {
            text: 'Галерея',
            path: 'gallery',
            isLink: true,
          },
          {
            text: 'Статьи',
            path: 'articles',
            isLink: true,
          },
        ],
      },
      {
        text: 'Продукты',
        path: '',
        dropdown: true,
        pointer: true,
        isLink: false,
        hasClass: false,
        dropLinks: [{
          text: 'CLASS',
          path: 'class',
          dropdown: true,
          isLink: true,
          dropSecondLinks: [{
            text: 'Платформа сбора и обработки данных ДЗЗ',
            path: 'data-sensing',
          },
          {
            text: 'Платформа сбора и обработки разнородных данных',
            path: 'data-hybrid',
          },
          {
            text: 'Платформа интеллектуального анализа данных',
            path: 'data-mining',
          },
          {
            text: 'CLASS.MOBILE',
            path: 'class-mobile',
          // dropdown: true,
          // dropSecondLinks: [{
          //   text: 'Мобильное приложение FIELD EXPERIMENT',
          //   path: 'field-experiment',
          //   pointer: true,
          // },
          // {
          //   text: 'Мобильное приложение DocPlant',
          //   path: 'doc-plant',
          //   pointer: true,
          // },
          // ],
          },
          {
            text: 'CLASS.SCORING',
            path: 'doc-plant',
            pointer: true,
            isLink: false,
          },
          ],
        },
        {
          text: 'CLASS.CLOUD',
          path: 'class',
          dropdown: true,
          pointer: true,
          isLink: false,
          dropSecondLinks: [{
            text: 'RiskAgro',
            path: 'risc-agro',
            isLink: true,
            className: 'line_coral',
          },
          {
            text: 'AGROMETRIKA',
            path: 'agro-metrika',
            isLink: true,
            className: 'line_orange',
          },
          {
            text: 'GeoCLASS',
            path: 'doc-plant',
            pointer: true,
            isLink: false,
          },
          {
            text: 'GeoCLASS.Cadastre',
            path: 'geo-cadastre',
            isLink: true,
          },
          {
            text: 'AgroDeposit',
            path: 'doc-plant',
            pointer: true,
            isLink: false,
          },
          {
            text: 'GeoCLASS.Education',
            path: 'doc-plant',
            pointer: true,
            isLink: false,
          },

          ],
        },

        {
          text: 'Услуги',
          path: '',
          dropdown: true,
          pointer: true,
          isLink: false,
          dropSecondLinks: [{
            text: 'Классификация посевных площадей',
            path: 'crop-area',
          },
          {
            text: 'Прогноз урожайности',
            path: 'forecast',
          },
          {
            text: 'Оценка урожайности',
            path: 'yield-eval',
          },
          {
            text: 'Оценка ущерба',
            path: 'loss-eval',
          },
          {
            text: 'Оценка и ранжирование сельскохозяйственных земель',
            path: 'rank-agro',
          },
          {
            text: 'Борьба с инвазивными видами растений',
            path: 'invasive-plants',
          },
          {
            text: 'Мониторинг тематических СМИ на территориях проведения исследований',
            path: 'obs-media',
          },
          {
            text: 'Мониторинг и прогнозирование метеоусловий',
            path: 'obs-weather',
          },
          ],
        },
        ],
      },
      {
        text: 'Отрасли',
        path: '',
        pointer: true,
        dropdown: true,
        hasClass: false,
        isLink: false,
        dropLinks: [{
          text: 'Сельское хозяйство',
          path: 'agriculture',
          dropdown: true,
          isLink: true,
          dropSecondLinks: [{
            text: 'Растениеводство',
            path: 'plant-growing',
          },
          {
            text: 'Пастбищное животноводство',
            path: 'stock-raising',
          },
          ],
        },
        {
          text: 'Страхование и финансы',
          path: 'ins-finance',
          dropdown: false,
          isLink: true,
        },
        {
          text: 'Лесное хозяйство',
          path: 'forestry',
          isLink: true,
        },
        {
          text: 'Экология',
          path: 'ecology',
          dropdown: true,
          isLink: true,
          dropSecondLinks: [{
            text: 'Карбоновые проекты',
            path: 'carbon-projects',
          },
          {
            text: 'Борьба с инвазивными растениями',
            path: 'invasive-plant',
          },
          ],
        },
        ],
      },
      {
        text: 'Технологии',
        path: 'technology',
        dropdown: true,
        isLink: true,
        dropLinks: [{
          text: 'ДЗЗ из космоса',
          path: 'sensing-space',
          isLink: true,
        },
        {
          text: 'ДЗЗ с помощью БПЛА',
          path: 'sensing-uav',
          isLink: true,
        },
        {
          text: 'Обработка материалов ДЗЗ',
          path: 'process-sensing',
          isLink: true,
        },
        {
          text: 'Большие данные (BIG DATA)',
          path: 'big-data',
          isLink: true,
        },
        {
          text: 'Технологии AI',
          path: 'tech-ai',
          isLink: true,
        },
        {
          text: 'Динамическое имитационное моделирование',
          path: 'simulation',
          isLink: true,
        },
        {
          text: 'Технология агроскаутинга',
          path: 'agro-scouting',
          isLink: true,
        },
        {
          text: 'Технология глобальной спутниковой навигации',
          path: 'satellite-nav',
          isLink: true,
        },
        ],
      },
      {
        text: 'Тарифы',
        path: 'rates',
        // pointer: true,
        // dropdown: false,
        // isLink: false,
        dropdown: false,
        isLink: true,
      },
      {
        text: 'Поддержка',
        path: 'support',
        dropdown: false,
        isLink: true,
      },
      {
        text: 'Контакты',
        path: 'contacts',
        dropdown: false,
        isLink: true,
      },
      ],
    };
  },
  computed: {
    tagLink() {
      return this.isLink ? 'router-link' : 'div';
    },
  },
  methods: {
    toggleHamburger() {
      this.$emit('closeModalMenu');
    },
  },
  components: {
    HeaderMenuArrow,
  },
};
</script>

<style lang="scss" scoped>
$white: #FFFFFF;
$gray: #AAADAF;
$dark-gray:#2B3138;
$blue: #4DB6BC;
$yellow: #F8A81B;
$sky-blue:#5598CF;
$coral: #FF7D75;

.navigation__list > .navigation__item > .navigation__link.disabled {
  cursor: pointer;
}

.navigation__list > .navigation__item.disabled > .navigation__link.disabled,
.navigation-dropdown__link.disabled {
  cursor: default;
  pointer-events: none;
}

.navigation__item.disabled .navigation-dropdown__list .navigation-dropdown__link {
  cursor: pointer;
}
.navigation-dropdown {
  &__list {
    display: none;
    left: -9999px;
    opacity: 0;
    transition: opacity .3s;
    position: absolute;
    min-width: 190px;
    z-index: 95;
    padding: 20px 0;
    margin-top: 10px;
    background: $dark-gray;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: -15px;
      height: 15px;
    }

    @media (max-width: 1180px) {
      display: block;
      opacity: 1;
      position: initial;
      padding: 0px;
      box-shadow: none;
      border-radius: 0;
      margin-top: 5px;
      background: initial;

      & .navigation-dropdown__list .navigation-dropdown__link {
        padding-left: 45px;
        cursor: pointer;

        @media (max-width: 1180px) {
          &:after {
            left: 45px;
          }
        }
      }
    }
  }

  &__link {
    text-transform: initial;
    color: $gray;
    font-family: 'montserratbold', sans-serif;
    font-size: 12px;
    line-height: 17px;
    padding: 15px;
    display: flex;
    width: 100%;
    position: relative;

    &:after {
      content: '';
      display: block;
      background-color: $blue;
      width: 0px;
      height: 2px;
      position: absolute;
      transition: width 0.3s cubic-bezier(0.51, 0.51, 0.51, 0.51);
      bottom: 12px;

    .line_orange & {
      background-color: $yellow;
    }

    .line_sky-blue & {
      background-color: $sky-blue;
    }

    .line_coral & {
      background-color: $coral;
    }

      @media (max-width: 1180px) {
        left: 30px;
      }
    }

    &:hover {
      background: $gray;
    }

    &.router-link-active {
      color: $white;

      &:after {
        width: 30px;
      }
    }

    @media (max-width: 1180px) {
      padding-left: 30px;
    }
  }

  &__item {
    transition: 0.3s;
    width: 100%;
    position: relative;

    &:hover>.navigation-dropdown__link {
      color: $white;
    }

    &:hover>.navigation-dropdown__link:after {
      width: 30px;
    }

    &:hover>.navigation-dropdown__list {
      top: -15px;
      display: block;
      left: 170px;
      opacity: 1;

      @media (max-width: 1180px) {
        display: block;
      }
    }
  }
}

.header_navigation {
  padding: 0 20px;
  position: relative;

  &-background {
    display: none;
    position: absolute;
    width: 100%;

    &__img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 1196px) {
    padding: 0 10px;
  }

  @media (max-width: 1180px) {
    padding: 0;
    position: absolute;
    display: none;
    top: 15px;

    &-background {
      display: block;
    }

    .sidebar-open & {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.navigation {
  &__list {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1180px) {
      flex-direction: column;
    }
  }

  &__link {
    color: $gray;
    font-family: 'montserratbold', sans-serif;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
    transition: 0.3s;
    display: flex;

    &:after {
      content: '';
      display: block;
      background-color: $blue;
      width: 0px;
      height: 2px;
      position: absolute;
      transition: width 0.3s cubic-bezier(0.51, 0.51, 0.51, 0.51);
      bottom: 0;

      @media (max-width: 1180px) {
        left: 15px;
      }
    }

    &.router-link-active {
      color: $white;

      &:after {
        width: 30px;
      }
    }

    @media (max-width: 1250px) {
      font-size: 11px;
    }

    @media (max-width: 1180px) {
      padding-left: 15px;
    }
  }

  &__item {
    margin-right: 13px;
    padding-right: 20px;
    position: relative;

    &:hover>.navigation-dropdown__list {
      display: block;
      left: 0;
      opacity: 1;
    }

    &:hover .navigation__link {
      color: $white;
    }

    &.active .navigation__link:after,
    &:hover .navigation__link:after {
      width: 30px;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }

    @media (max-width: 1575px) {
      margin-right: 0;
    }

    @media (max-width: 1350px) {
      padding-right: 15px;
    }

    @media (max-width: 1275px) {
      padding-right: 13px;
    }

    @media (max-width: 1180px) {
      margin-right: 0;
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
}
</style>
